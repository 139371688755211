<template>
  <div class="timepicker-container">
    <div class="timepicker-header">
      <h6 class="header normal-semi unselectable">
        {{ $t('Components.Timepicker.select_time') }}
      </h6>
      <div class="time__container">
        <div class="timepicker-header__icon-wrapper">
          <svg-icon src="/icons/clock_filled.svg" />
        </div>
        <h6 class="time small-regular unselectable">
          {{ startTime }} - {{ endTime }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TimepickerHeader',
  props: {
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>

  .timepicker-header {
    display: flex;
    justify-content: space-between;
    .time__container{
      gap: 0.5rem;
      display: flex;
      margin: auto 0;
    }

    h6 {
      margin: 0;
    }

    .time {
      margin: auto 0;
      line-height: 1;
      display: flex;
      align-items: center;
    }

    .timepicker-header__icon-wrapper {
      margin: auto;

      .icon {
        display: flex;
      }
    }
  }

</style>
