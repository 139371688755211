<template>
  <div
    class="flex button noselect"
    @click="buttonClicked"
  >
    <div
      v-if="!isOpen"
      :key="isOpen"
      class="icon-holder"
    >
      <svg-icon
        :src="arrowIcon"
      />
    </div>
    <div
      v-else
      :key="isOpen"
      class="icon-holder"
    >
      <svg-icon
        src="/icons/dash.svg"
      />
    </div>
    <h6 class="small-regular">
      {{ isOpen ? openedTitle : closedTitle }}
    </h6>
  </div>
</template>

<script>

import i18n from '@/i18n';

export default {
  name: 'AccordionButton',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    arrowDirection: {
      describe: 'The direction of the arrow, up or down',
      type: String,
      required: false,
      default: 'down',
    },
    closedTitle: {
      type: String,
      required: false,
      default: i18n.t('Components.AccordionButton.show_more'),
    },
    openedTitle: {
      type: String,
      required: false,
      default: i18n.t('Components.AccordionButton.show_less'),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    arrowIcon() {
      let src;
      if (this.arrowDirection === 'down') {
        src = '/icons/arrow_down.svg';
      } else if (this.arrowDirection === 'up') {
        src = '/icons/arrow_up.svg';
      }
      return src;
    },
  },
  created() {
    this.isOpen = this.open;
  },
  methods: {
    buttonClicked() {
      this.isOpen = !this.isOpen;
      this.$emit('clicked', this.isOpen);
    },
  },
};

</script>

<style lang="scss" scoped>
  .button {
    width: fit-content;
    // TODO: Add colors to theme-files
    background-color: #F5F5F7;
    border: 1px solid #D9D9D9;
    border-radius:  0.5rem;
    padding: 0.5rem 0.7rem;
    transform: background-color 0.3s;

    h6 {
      margin: auto 0;
    }

    &:hover {
      cursor: pointer;
      background-color: #ecebeb;
    }

    .icon-holder {
      margin: 0 0.5rem 0.25rem 0;
    }
  }
</style>
