import { getUserSmall, getColleagues } from '@/util/api';
import BookingsService from '@/Services/Bookings/bookings.service';
import ExtendedDate from './extended.date.class';
import i18n from '../i18n';

const THRITY_MILI_MIN = 1800000;
const findOwner = (booking) => (item) => (booking.Owner === item.Username);

class ActiveZoneBookings {
  constructor(zid, from = new ExtendedDate().getTime(), until = new ExtendedDate().setHours(24, 0, 0, 0), fetchusers = false) {
    this.zid = zid;
    this.from = from;
    this.until = until;
    this.bookings = [];
    this.shouldFetchUsers = fetchusers;
  }

  async init() {
    this.bookings = await BookingsService.getCachedResourceBookings(this.zid, this.from, this.until);
    this.bookings.sort((a, b) => a.From - b.From);
    this.bookings = this.bookings.filter((b) => b.Status !== 'END');
    await this.addUserDataToBookings();
  }

  async addUserDataToBookings() {
    if (!this.shouldFetchUsers) return;
    /** If no bookings, dont bother to fetch colleauges. */
    if (this.bookings.length < 1) return;

    /** If only one booking its better to only fetch one user as usual. */
    if (this.bookings.length < 2) {
      await this.addUserDataToBooking();
      return;
    }

    /** Fetch colleauges for realm */
    const { data: { Users } } = await getColleagues();

    this.bookings.forEach((booking) => {
      let User = Users.find(findOwner(booking));
      if (!User) {
        User = {
          Name: i18n.t('Views.Book.guest'),
          AvatarUrl: '',
        };
      }
      Object.assign(booking, { User });
    });
  }

  async addUserDataToBooking() {
    const [booking] = this.bookings;
    try {
      if (booking) {
        let { data: User } = await getUserSmall(booking.Owner);
        if (!User) {
          User = {
            Name: i18n.t('Views.Book.guest'),
            AvatarUrl: '',
          };
        }
        Object.assign(booking, { User });
      }
    } catch (e) {
      const User = {
        Name: i18n.t('Views.Book.guest'),
        AvatarUrl: '',
      };
      Object.assign(booking, { User });
    }
  }

  /* Init needs to be run first */
  caluclateAvailableSlotsInTimeline() {
    const now = new ExtendedDate();
    const dayEnd = new ExtendedDate(now).setHours(24, 0, 0, 0);
    // const seenZid = [];

    // If no bookings, availble til end of day
    if (!this.bookings.length) {
      this.bookings = [{ From: now.getTime(), Until: dayEnd }];
      return;
    }
    const timeLine = [];
    const sorted = this.bookings.toSorted((a, b) => a.From - b.From);
    let from = sorted[0].From;
    let prevUntil = sorted[0].Until;
    if (from - now.getTime() > THRITY_MILI_MIN) {
      prevUntil = from;
      timeLine.push({
        From: now.getTime(),
        Until: from,
        IsGap: true,
      });
    }
    for (let i = 0; i < sorted.length; i++) {
      const booking = sorted[i];
      from = booking.From;
      if (from <= prevUntil) {
        timeLine.push(booking);
        prevUntil = booking.Until;
        continue;
      }
      timeLine.push({
        From: prevUntil,
        Until: from,
        IsGap: true,
      });
      timeLine.push(booking);
      prevUntil = booking.Until;
    }
    if (prevUntil < dayEnd) {
      timeLine.push({
        From: prevUntil,
        Until: dayEnd,
        IsGap: true,
      });
    }

    this.bookings = timeLine;
  }
}

export default ActiveZoneBookings;
