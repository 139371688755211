<template>
  <div class="main-container">
    <div
      v-if="TimepickerController && TimepickerController.hasLoadedOnce"
      class="grid-container"
    >
      <TimepickerTimeslot
        v-for="timeslot in TimepickerController.currentlyDisplayedTimeslots"
        :key="timeslot.index"
        :timeslot="timeslot"
        class="grid-item"
        :class="{'selected': isTimeslotSelected(timeslot.index),
                 'middle-item-left': timeslot.middleItemLeft,
                 'middle-item-right': timeslot.middleItemRight,
                 'occupied': timeslot.occupied && !timeslot.isDisabled,
                 'disabled': timeslot.isDisabled,
        }"
        @timeslot-clicked="timeslotClicked"
      />
    </div>
  </div>
</template>

<script>

import TimepickerTimeslot from './timepicker.timeslot.component.vue';
import TimepickerController from './timepicker.controller';

export default {
  name: 'TimepickerTimeslots',
  components: {
    TimepickerTimeslot,
  },
  props: {
    timepickerController: {
      type: Object,
      required: true,
    },
    itemsToShow: {
      type: Number,
      required: false,
      default: 16,
    },
  },
  data() {
    return {
      TimepickerController,
    };
  },
  methods: {
    timeslotClicked(index) {
      if (TimepickerController.allTimeslots[index].isDisabled || TimepickerController.allTimeslots[index].occupied) { return; }
      this.TimepickerController.timeslotClicked(index);
    },
    isTimeslotSelected(index) {
      return TimepickerController.isTimeslotSelected(index);
    },
  },
};

</script>

<style lang="scss" scoped>

  $item-height: 2.5rem;
  $row-gap: 16px;

  .main-container {
    overflow-y: auto;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(0, 1fr); // Setting 0 as it will then be correct depending on the number of items shown
    column-gap: 0.8rem;
    row-gap: $row-gap;

    .grid-item {
      height: $item-height;
      line-height: $item-height;

      &:not(.disabled, .occupied):hover {
        cursor: pointer;
      }

      &:not(.selected, .disabled, .occupied):hover {
        background-color: var(--free-color);
      }

      &.middle-item-left {
        margin-left: 0.3rem;
      }

      &.middle-item-right {
        margin-left: -0.3rem;
      }

      &.occupied {
        background-color: var(--occupied-color);
      }

      &.disabled {
        background-color: var(--disabled);
      }
    }
  }

  .selected {
    background-color: var(--away-color);
  }

</style>
