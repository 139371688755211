/* eslint-disable no-unused-vars */
import ExtendedDate from '@/classes/extended.date.class';
import ActiveZoneBookings from '@/classes/active.zone.bookings.class';

const MIN_30 = 1800000;
const MIN_15 = 1000 * 60 * 15;
class TimepickerController {
  constructor() {
    /* * * * * * * * * * Settings * * * * * * * * * */

    /* Sets the default starting hour of the timepicker,
      in case no time is set with the date */
    this.defaultStartHour = 6;

    /* * * * * * * * * * * * * * * * * * * * * * * */

    this.allTimeslots = [];
    this.currentlyDisplayedTimeslots = [];

    this.hasLoadedOnce = false;

    this.indexStart = null;
    this.indexEnd = null;

    this.date = null;
    this.endDate = null;

    this.startHourIndex = NaN;

    this.startAmountShown = NaN;
    this.showEarlier = false;
    this.showLater = false;
    this.hasEndDate = false;

    this.zid = null;
    this.bookings = [];
  }

  reset() {
    this.allTimeslots = [];
    this.currentlyDisplayedTimeslots = [];

    this.hasLoadedOnce = false;

    this.indexStart = null;
    this.indexEnd = null;

    this.date = null;
    this.endDate = null;

    this.startHourIndex = NaN;

    this.startAmountShown = NaN;
    this.showEarlier = false;
    this.showLater = false;
    this.hasEndDate = false;
    this.activeBid = null;

    this.zid = null;
    this.bookings = [];
  }

  /**
   * @param {ExtendedDate} startDate - The date of which the timepicker is set for. Defaults to today.
   * @param {ExtendedDate} endDate - The date of which the timepicker is set to end. Defaults to undefined.
   * @param {number} zid - The zone id of which the timepicker is set for.
   * @param {number} amountOfItemsToShow - The amount of timeslots to show as standard. Defaults to 16.
   * @param {boolean} noSlotsDisabled - Set to true to enable all timeslots no matter what. Defaults to false.
   * @param {number} editBid -  Booking id, to hide the the booking, in order to make the time they occupy availble to edit.
   */
  async init(startDate = new ExtendedDate(), endDate = undefined, zid = null, amountOfItemsToShow = 16, noSlotsDisabled = false, editBid = null) {
    this.reset();

    this.noSlotsDisabled = noSlotsDisabled;
    this.date = startDate;
    this.activeBid = editBid;

    if (endDate) {
      this.endDate = endDate;
    }

    if (zid) {
      const zoneBookings = new ActiveZoneBookings(zid, new ExtendedDate(this.date).setHours(0, 0, 0, 0), new ExtendedDate(this.date).setHours(24, 0, 0, 0), false);
      await zoneBookings.init();
      this.bookings = this.activeBid ? zoneBookings.bookings.filter(({ Bid }) => this.activeBid !== Bid) : zoneBookings.bookings;
    }

    this.createTimeslots(startDate);
    this.setOccupancy();
    this.setStartIndex(startDate);
    this.setCurrentlyDisplayedTimeslots(amountOfItemsToShow);

    if (endDate) {
      this.hasEndDate = true;

      // To make sure we dont select the next timeslot, aka 30 more minutes
      if (endDate.getTimeMillisWithoutDateOffset(true) % (1000 * 60 * 30) === 0) {
        // eslint-disable-next-line no-param-reassign
        endDate = new ExtendedDate(endDate - 1);
      }

      this.timeslotClicked(this.getIndexFromMillisTimestamp(startDate.getTimeMillisWithoutDateOffset()));
      this.timeslotClicked(this.getIndexFromMillisTimestamp(endDate.getTimeMillisWithoutDateOffset(true)));
    }

    // Needs to be last
    this.hasLoadedOnce = true;
  }

  // Needs to be AFTER allTimeslots is created
  setStartIndex(date) {
    const now = new ExtendedDate();
    /* If date is today and now is more than the start time of the timepicker(date),
    display now; else display starttime of timepicker (i.e a startime of a booking) */
    let start = date?.isToday() && now.getHours() > date.getHours() ? now.getHours() : date.getHours();
    /* However, if we do not have a booking and it is not today
    dispaly default start hour (i.e. 06:00) */
    start = !date?.isToday() && !this.endDate ? this.defaultStartHour : start;
    // Checks to make sure start is a valid index
    start = start % 2 === 0 ? start : start - 1;
    start = start < 0 ? 0 : start;
    start = start > 23 ? 23 : start;
    start *= 2; // Times 2 because each hour is 2 timeslots

    this.startHourIndex = start;
  }

  createTimeslots(date) {
    const minute = 1000 * 60;

    /* Creates all the timeslots, NOT the ones that are actually displayed */
    this.alltimeslots = [];
    for (let i = 0; i < 48; i += 1) {
      const currentTimestamp = minute * 30 * i;
      const nextTimestamp = minute * 30 * (i + 1);
      this.allTimeslots.push({
        index: i,
        timestamp: currentTimestamp, // 30 minute leaps
        middleItemLeft: i % 2 === 0 && i % 4 !== 0,
        middleItemRight: (i + 1) % 2 === 0 && (i + 1) % 4 !== 0,
        isDisabled: this.slotIsDisabled(currentTimestamp, nextTimestamp),
        occupied: false,
        isDisabledByClass: false,
      });
    }
  }

  setOccupancy() {
    this.allTimeslots.forEach((timeslot) => {
      const slot = timeslot;
      slot.occupied = this.slotIsOccupied(slot.timestamp);
      Object.assign(timeslot, slot);
    });
  }

  slotIsOccupied(currentTimestamp) {
    let occupied = false;
    this.bookings.forEach((booking) => {
      const fromTimestamp = new ExtendedDate(booking.From).getTimeMillisWithoutDateOffset();
      const untilTimestamp = new ExtendedDate(booking.Until).getTimeMillisWithoutDateOffset(true);
      /* subtract 30 min because a booking can start 10:01 (from time) etc,
        and it should still occupy 10:00 slot  */
      if (currentTimestamp > fromTimestamp - MIN_30 && currentTimestamp < untilTimestamp) {
        occupied = true;
      }
    });
    return occupied;
  }

  slotIsDisabled(currentTimestamp, nextTimestamp) {
    if (this.noSlotsDisabled) return false;
    const currentTime = new ExtendedDate().getTimeMillisWithoutDateOffset();
    if (this.date?.isToday()) {
      return (currentTimestamp < currentTime && nextTimestamp < currentTime)
        || ((nextTimestamp - currentTime) < MIN_15 && !this.slotIsOccupied(currentTimestamp) && this.slotIsOccupied(nextTimestamp));
    }
    return false;
  }

  canShowEarlierTimes() {
    if (this.currentlyDisplayedTimeslots[0].index === 0 && !this.showEarlier && !this.hasEndDate) {
      return false;
    }
    return true;
  }

  getIndexFromMillisTimestamp(millisTimestamp) {
    // If input timestamp is higher than the last timestamp in the timepicket, return the last index
    const lastSlot = this.allTimeslots[this.allTimeslots.length - 1];
    if (millisTimestamp >= lastSlot?.timestamp) return lastSlot.index;
    /* else return the next index, which closest to the timestamp - 30 min
    i.e. 09:28 -> get 09:00 index */
    const slots = this.allTimeslots.filter(({ timestamp }) => timestamp > millisTimestamp - MIN_30);
    return slots[0].index;
  }

  isTimeslotSelected(index) {
    return index === this.indexStart
          || index === this.indexEnd
          || (index > this.indexStart && index < this.indexEnd);
  }

  getTimestampFromIndex(index) {
    return this.allTimeslots[index].timestamp;
  }

  isLastActiveSlot(index) {
    const now = new ExtendedDate().getTimeMillisWithoutDateOffset();
    const slots = this.allTimeslots.filter(({ timestamp }) => timestamp > now - MIN_30);
    const lastActiveIndex = this.getIndexFromMillisTimestamp(slots[0]?.timestamp);
    return (lastActiveIndex === index);
  }

  timeslotClicked(index) {
    // If nothing is selected, select the clicked slot
    if (this.indexStart === null) {
      this.indexStart = index;
      this.disableTimeslotsOnItemClicked();
      return;
    }
    /* If we do not yet have an endslot but have an start slot, select the clicked slot
    given that it is a slot larger than the start slot */
    if (this.indexEnd === null && index >= this.indexStart) {
      this.indexEnd = index;
      return;
    }
    // If clicking the start slot when we also have a selected end slot, deselect all
    if (index === this.indexStart) {
      this.indexStart = null;
      this.indexEnd = null;
      this.resetDisabledTimeslots();
      return;
    }
    /* if clicking a slot before the start slot, the start slot should become the end slot
      and the clicked slot should become the start slot */
    if (this.indexEnd === null && index < this.indexStart) {
      this.indexEnd = this.indexStart;
      this.indexStart = index;
      return;
    }
    /* If a start and end slot is selected, then if clicking a slot before
    start slot, that should become the new start slot */
    if (index < this.indexStart) {
      this.indexStart = index;
      return;
    }
    /* If clicking the last active slot, deselect all
    (last active slot = first slot that a booking stretches over if the slots before is in the past,
    mainly used for editing ongoing bookings) */
    if (this.isLastActiveSlot(index)) {
      this.indexStart = null;
      this.indexEnd = null;
      this.resetDisabledTimeslots();
      return;
    }
    /*  If a start and end slot is selected, then if clicking a slot after
    start slot, that should become the new end slot */
    if (index > this.indexStart) {
      this.indexEnd = index;
    }
  }

  /**
  * @param {number} amountToShow - The number of items to display in the timepicker. 0-48.
  */
  setCurrentlyDisplayedTimeslots(amountToShow = 16) {
    // Safety checks to make sure we're inside the bounds
    let amount = amountToShow;
    if (amount + this.startHourIndex > 48) {
      amount = 48 - this.startHourIndex;
    }

    this.startAmountShown = amount;

    // Creates the actual array of timeslots we're displaying
    this.currentlyDisplayedTimeslots = [];
    for (let i = this.startHourIndex; i < this.startHourIndex + amount; i += 1) {
      this.currentlyDisplayedTimeslots.push(this.allTimeslots[i]);
    }
  }

  /**
   * @param {boolean} value - True to show more, false to show less.
   */
  toggleEarlierTimeslots(value) {
    const startIx = value ? 0 : this.startHourIndex;
    this.showEarlier = value;

    const end = this.showLater ? 48 : this.startHourIndex + this.startAmountShown;

    this.currentlyDisplayedTimeslots = [];
    for (let i = startIx; i < end; i += 1) {
      this.currentlyDisplayedTimeslots.push(this.allTimeslots[i]);
    }
  }

  /**
   * @param {boolean} value - True to show more, false to show less.
   */
  toggleLaterTimeslots(value) {
    this.showLater = value;
    const stop = value ? 48 : this.startHourIndex + this.startAmountShown;
    const start = this.showEarlier ? 0 : this.startHourIndex;

    this.currentlyDisplayedTimeslots = [];
    for (let i = start; i < stop; i += 1) {
      this.currentlyDisplayedTimeslots.push(this.allTimeslots[i]);
    }
  }

  canDisplayMoreItems() {
    if (this.currentlyDisplayedTimeslots[this.currentlyDisplayedTimeslots.length - 1].index === 47 && !this.showLater) {
      return false;
    }
    return true;
  }

  resetSlots() {
    this.indexStart = null;
    this.indexEnd = null;
  }

  setStartSlotWithTimestamp(milliseconds) {
    // We put in a millisecond value that is equal to a half or whole hour
    const slot = this.allTimeslots.find(({ timestamp }) => timestamp === milliseconds);
    if (slot) {
      this.indexStart = slot.index;
    } else {
      // The value we put in is not divideable by half/whole hour
      // Find closest timeslot and set that instead.
      for (let i = 0; i < this.allTimeslots.length; i += 1) {
        const currentTimeslot = this.allTimeslots[i];
        if (milliseconds <= MIN_30) {
          this.indexStart = currentTimeslot.index;
          return;
        }
        // Milliseconds is between prev and current milliseconds, set prev timeslot
        if (currentTimeslot.timestamp > milliseconds) {
          this.indexStart = this.allTimeslots[i - 1].index;
          return;
        }
      }
    }
  }

  setEndSlotWithTimestamp(milliseconds) {
    // We put in a millisecond value that is equal to a half or whole hour
    const slot = this.allTimeslots.find(({ timestamp }) => timestamp === milliseconds);
    if (slot) {
      this.indexEnd = slot.index;
    } else {
      // The value we put in is not divideable by half/whole hour
      // Find closest timeslot and set that instead.
      for (let i = 0; i < this.allTimeslots.length; i += 1) {
        const currentTimeslot = this.allTimeslots[i];
        if (milliseconds <= MIN_30) {
          this.indexEnd = currentTimeslot.index;
          return;
        }
        // Milliseconds is between prev and current milliseconds, set prev timeslot
        if (currentTimeslot.timestamp > milliseconds) {
          this.indexEnd = this.allTimeslots[i - 1].index;
          return;
        }
      }
    }
  }

  startTimestamp() {
    if (this.indexStart) {
      return this.allTimeslots[this.indexStart].timestamp;
    }
    return 0;
  }

  endTimstamp() {
    if (this.indexEnd) {
      return this.getTimestampFromIndex(this.indexEnd) + (1000 * 60 * 30);
    } if (this.indexStart) {
      return this.getTimestampFromIndex(this.indexStart) + (1000 * 60 * 30);
    }
    return 0;
  }

  headerStartTime() {
    if (this.indexStart) {
      const date = new ExtendedDate(this.date).setHours(0, 0, 0, this.allTimeslots[this.indexStart].timestamp);
      return new ExtendedDate(date).localeTimeString();
    }
    const dayStart = new ExtendedDate(this.date).setHours(0, 0, 0, 0);
    return new ExtendedDate(dayStart).localeTimeString();
  }

  headerEndTime() {
    if (this.indexEnd) {
      const time = new ExtendedDate().setHours(0, 0, 0, this.getTimestampFromIndex(this.indexEnd) + (1000 * 60 * 30));
      return new ExtendedDate(time).localeTimeString();
    }
    // Because if only the starttime is selected, we want to show the endtime as the starttime + 30 minutes
    if (this.indexStart) {
      const time = new ExtendedDate().setHours(0, 0, 0, this.getTimestampFromIndex(this.indexStart) + (1000 * 60 * 30));
      return new ExtendedDate(time).localeTimeString();
    }
    const dayEnd = new ExtendedDate(this.date).setHours(24, 0, 0, 0);
    return new ExtendedDate(dayEnd).localeTimeString();
  }

  disableTimeslotsOnItemClicked() {
    let shouldDisable = false;
    for (let i = this.indexStart; i >= 0; i -= 1) {
      if (this.allTimeslots[i]?.occupied && !shouldDisable) {
        if (i !== this.indexStart) {
          shouldDisable = true;
        }
      }

      if (shouldDisable && !this.allTimeslots[i].isDisabledByClass && !this.allTimeslots[i].isDisabled) {
        this.allTimeslots[i].isDisabled = true;
        this.allTimeslots[i].isDisabledByClass = true;
      }
    }

    shouldDisable = false;
    for (let i = this.indexStart; i < this.allTimeslots.length; i += 1) {
      if (this.allTimeslots[i]?.occupied && !shouldDisable) {
        if (i !== this.indexStart) {
          shouldDisable = true;
        }
      }

      if (shouldDisable && !this.allTimeslots[i].isDisabledByClass && !this.allTimeslots[i].isDisabled) {
        this.allTimeslots[i].isDisabled = true;
        this.allTimeslots[i].isDisabledByClass = true;
      }
    }
  }

  resetDisabledTimeslots() {
    for (let i = 0; i < this.allTimeslots.length; i += 1) {
      if (this.allTimeslots[i].isDisabledByClass) {
        this.allTimeslots[i].isDisabled = false;
        this.allTimeslots[i].isDisabledByClass = false;
      }
    }
  }
}

export default new TimepickerController();
