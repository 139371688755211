<template>
  <div
    class="grid-item noselect"
    @click="timeslotClicked"
  >
    <span>
      <h6 class="small-regular"> {{ timestampToString }} </h6>
    </span>
  </div>
</template>

<script>

import ExtendedDate from '@/classes/extended.date.class';

export default {
  name: 'TimepickerTimeslot',
  props: {
    timeslot: {
      type: Object,
      required: true,
    },
  },
  computed: {
    timestampToString() {
      const date = new ExtendedDate().setHours(0, 0, 0, this.timeslot.timestamp);
      return new ExtendedDate(date).localeTimeString();
    },
  },
  methods: {
    timeslotClicked() {
      this.$emit('timeslot-clicked', this.timeslot.index);
    },
  },
};

</script>

<style lang="scss" scoped>

  .grid-item {
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 10px;
    text-align: center;

    span {
      display: inline-block;
      vertical-align: baseline;

      h6 {
        color: white;
      }
    }
  }

</style>
